<template>
  <div class="about" id="about-view">
    <h1><strong>DAFAL LTDA</strong></h1>
    <div class="content-container">
      <div class="image-container">
        <img src="https://i.imgur.com/bairYf3.jpg" alt="Imagen del edificio" class="image" />
      </div>
      <div class="contenido">
        <h3><strong>Transparencia al máximo</strong></h3>
        <p>
          Bitácora Electrónica. Trabajamos con Notion, plataforma 100% gratuita para que la comunidad pueda en cualquier momento hacer seguimiento de la situación en que se encuentra su condominio, estableciendo para la Administración la responsabilidad de mantenerla actualizada y detallada.
        </p>
        <h3><strong>Democracia participativa</strong></h3>
        <p>
          Somos creyentes de que una comunidad participativa, siempre será mejor administrada. Buscamos desarrollar métodos de participación en las comunidades que apunten en esta dirección. Creamos instancias de comunicación directo entre Administración-residentes, dónde tengan la oportunidad de resolver sus dudas de manera rápida y directa.
        </p>
        <h3><strong>Gastos comunes al día</strong></h3>
        <p>
          Contamos con los mecanismos necesarios para transparentar y poner al día las obligaciones económicas de la comunidad, evitando devengar cobros de proveedores que puedan prestarse para movimientos poco transparentes y ajustando la morosidad a través de convenios de pagos y vías de ejecución.
        </p>
        <h3><strong>Presupuestos de proveedores</strong></h3>
        <p>
          Tenemos una política de tres presupuestos para trabajos cuyos valores sean superior a 5 UF, que se transparenta públicamente en nuestra Bitácora Electrónica. Además, trabajamos en base a presupuesto anual de 12 meses, para que la gente tenga certeza de antemano en que se usaran sus recursos.
        </p>
        <h3><strong>Asesoría legal y contable</strong></h3>
        <p>
          Somos dos socios del área legal y contable que comenzó a trabajar en conjunto a la publicación de la Nueva Ley de Copropiedad 21.442 y el Reglamento Tipo de Copropiedad. Tenemos máxima nota en la Pontificia Universidad Católica de Chile en Administración de Edificios y Condominios.
        </p>
        <h3><strong>Reglamento de copropiedad</strong></h3>
        <p>
          Nuestro servicio ofrece además la producción de un Reglamento de Copropiedad 100% personalizado a la realidad del condominio, con participación de grupos de propietarios, donde nuestros principios de democracia y transparencia queden plasmados.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.about h1 {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 20px;
}

.content-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;
  margin: 0;
  gap: 20px;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contenido {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;
}

.contenido h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-align: center; /* Centra los títulos */
}

.about p {
  text-align: justify; /* Justifica el texto */
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    height: auto;
    align-items: center; /* Centra el contenido en dispositivos móviles */
    text-align: center;
  }

  .image-container {
    width: 100%;
  }

  .image {
    height: auto;
  }

  .contenido {
    width: 90%; /* Reduce el ancho para centrar el texto */
    padding: 20px;
    text-align: center; /* Asegura que el contenido esté centrado */
  }

  .contenido h3 {
    text-align: center;
  }

  .about p {
    text-align: justify; /* Justifica el texto */
    margin-bottom: 15px;
  }
}
</style>
